@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.s-min-height {
  min-height: 300px;
  @media only screen and (max-width: 600px) {
    min-height: auto;
  }
}
